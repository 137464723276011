import * as React from 'react';

function header_styles(small) {
  const base = 'text-4xl font-display tracking-tight text-chocolate';
  if (small) {
    return base;
  } else {
    return `${base} sm:text-5xl xl:text-5xl`;
  }
}

export function Header({ children, small }) {
  return <h1 className={header_styles(small)}>{children}</h1>;
}

export function GoldTopper({ children }) {
  return (
    <h3 className="text-bright_gold_120 font-display text-base tracking-wide lg:pb-2 uppercase">
      {children}
    </h3>
  );
}
