import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Header } from "../components/atoms";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Privacy() {
  const { t } = useTranslation();
  return (
    <Layout title={t("Privacy Policy")}>
      <div className="mt-16 max-w-6xl px-8 mx-auto">
        <Header>Privacy Policy</Header>
        <div className="pt-8 text-chocolate text-justify">
          <p>
            amplified ai, Inc. (“
            <strong>Amplified</strong>
            ,” “<strong>we</strong>
            ,” “<strong>our</strong>
            ,” or “<strong>us</strong>
            ”) is committed to protecting your privacy. This Privacy Policy
            explains how your personal information is collected, used and
            disclosed by Amplified. This Privacy Policy applies to our websites,
            our online services and any software or other service on which this
            Privacy Policy appears (collectively, our “<strong>Service</strong>
            ”). By accessing or using our Service, you signify that you have
            read, understood and agree to our collection, storage, use and
            disclosure of your personal information as described in this Privacy
            Policy and our Terms of Use, available at
            <a href="https://app.amplified.ai/terms-of-use">
              https://app.amplified.ai/terms-of-use
            </a>
            .
          </p>
          <h3 className="my-2">
            <strong>
              1. WHAT INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE?
            </strong>
          </h3>
          <p>The categories of information we collect may include:</p>
          <ul>
            <li>
              <p>
                <strong>Information you provide to us directly.</strong> We may
                collect personal information, such as your name, phone number,
                company information, location, and e-mail address when you when
                you register for our Service, access or use our Service, or
                otherwise communicate with us. We may also collect information
                about the content you make available through the Service (“
                <strong>User Content</strong>
                ”). Please remember that Amplified may, but has no obligation
                to, monitor, record, and store User Content in order to protect
                your safety or the safety of other users, to assist with
                regulatory or law enforcement efforts, or to protect and defend
                our rights and property. We may also collect any communications
                between you and Amplified and any other information you provide
                to Amplified. By using the Service, you consent to the
                recording, storage and disclosure of such communications you
                send or receive for these purposes.
              </p>
            </li>
            <li>
              <p>
                <strong>Data collected through the use of the Service</strong>.
                We collect information about how you use the Service and your
                actions on the Service. Please see the “How We Use Cookies and
                Other Tracking Technology to Collect Information” below for
                further details.
              </p>
            </li>
            <li>
              <p>
                <strong>Location Information.</strong> We collect your unique
                user identifier and your location through GPS, WiFi, or wireless
                network triangulation in order to obtain your location for the
                purposes of providing our Service. We maintain location
                information only so long as is reasonable to provide the Service
                and then delete location data tied to your personal information.
                We may maintain de-identified location data for a longer period
                of time in order to analyze aggregate trends and metrics.
              </p>
            </li>
            <li>
              <p>
                <strong>Information we receive from third parties.</strong> From
                time to time, we may receive information about you from third
                parties. We may also collect information about you that is
                publicly available.
              </p>
            </li>
          </ul>
          <p>
            We use this information to operate, maintain, and provide to you the
            features and functionality of the Service, to improve the Service,
            and to communicate directly with you, such as to send you email
            messages and push notifications. We may also send you
            Service-related emails or messages (e.g., account verification,
            change or updates to features of the Service, technical and security
            notices). For more information about your communication preferences,
            see “Control Over Your Information” below.
          </p>
          <h3 className="my-2">
            <strong>
              2. HOW WE USE COOKIES AND OTHER TRACKING TECHNOLOGY TO COLLECT
              INFORMATION.
            </strong>
          </h3>
          <p>
            We, and our third-party partners, automatically collect certain
            types of usage information when you visit our Service, read our
            emails, or otherwise engage with us. We typically collect this
            information through a variety of tracking technologies, including
            cookies, Flash objects, web beacons, file information and similar
            technology (collectively, “<strong>tracking technologies</strong>
            ”). For example, we collect information about your device and its
            software, such as your IP address, browser type, Internet service
            provider, platform type, device type, operating system, date and
            time stamp, a unique ID that allows us to uniquely identify your
            browser, mobile device or your account, and other such information.
            We also collect information about the way you use our Service, for
            example, the site from which you came and the site to which you are
            going when you leave our website, the pages you visit, the as you
            click, how frequently you access the Service, whether you open
            emails or click the as contained in emails, whether you access the
            Service from multiple devices, and other actions you take on the
            Service. When you access our Service from a mobile device, we may
            collect unique identification numbers associated with your device or
            our mobile application, mobile carrier, device type, model and
            manufacturer, mobile device operating system brand and model, phone
            number, and depending on your mobile device settings, your
            geographical location data, including GPS coordinates (e.g.,
            latitude and/or longitude) or similar information regarding the
            location of your mobile device, or we may be able to approximate a
            device’s location by analyzing other information, like an IP
            address. We may collect analytics data, or use third-party analytics
            tools (including, without limitation, Google Analytics and Flurry
            Analytics), to help us measure traffic and usage trends for the
            Service and to understand more about the demographics of our users.
            We may also work with third-party partners to employ technologies,
            including the application of statistical modeling tools, which
            attempt to recognize you across multiple devices. Although we do our
            best to honor the privacy preferences of our users, we are unable to
            respond to Do Not Track signals set by your browser at this time.
          </p>
          <p>
            We use or may use the data collected through tracking technologies
            to: (a) remember information so that you will not have to re-enter
            it during your visit or the next time you visit the site; (b)
            identify you across multiple devices; (c) provide and monitor the
            effectiveness of our Service; (d) monitor aggregate metrics such as
            total number of visitors, traffic, usage, and demographic patterns
            on our website; (e) diagnose or fix technology problems; and (f)
            otherwise to plan for and enhance our Service.
          </p>
          <p>
            If you would prefer not to accept cookies, most browsers will allow
            you to: (i) change your browser settings to notify you when you
            receive a cookie, which lets you choose whether or not to accept it;
            (ii) disable existing cookies; or (iii) set your browser to
            automatically reject cookies. Please note that doing so may
            negatively impact your experience using the Service, as some
            features and services on our Service may not work properly.
            Depending on your mobile device and operating system, you may not be
            able to delete or block all cookies. You may also set your e-mail
            options to prevent the automatic downloading of images that may
            contain technologies that would allow us to know whether you have
            accessed our e-mail and performed certain functions with it.
            Deleting cookies does not delete Local Storage Objects (LSOs) such
            as Flash objects and HTML5. You can learn more about Flash objects -
            including how to manage privacy and storage settings for Flash
            cookies – on Adobe’s website or by clicking here. If you choose to
            delete Flash objects from our sites, then you may not be able to
            access and use all or part of the sites or benefit from the
            information and services offered.
          </p>
          <h3 className="my-2">
            <strong>3. SHARING OF YOUR INFORMATION</strong>
          </h3>
          <p>
            We may share your personal information in the instances described
            below. For further information on your choices regarding your
            information, see the “Control Over Your Information” section below.
          </p>
          <p>We may share your personal information with:</p>
          <ul>
            <li>
              <p>
                Third-party vendors and other service providers that perform
                services on our behalf, as needed to carry out their work for
                us, including, without limitation, our payment processor Stripe;
              </p>
            </li>
            <li>
              <p>Third parties at your request;</p>
            </li>
            <li>
              <p>
                Other companies and brands owned or controlled by amplified ai,
                Inc. and other companies owned by or under common ownership as
                Amplified, which also includes our subsidiaries (i.e., any
                organization we own or control) or our ultimate holding company
                (i.e., any organization that owns or controls us) and any
                subsidiaries it owns. These companies will use your personal
                information in the same way as we can under this Privacy Policy;
              </p>
            </li>
            <li>
              <p>
                Other parties in connection with a company transaction, such as
                a merger, sale of company assets or shares, reorganization,
                financing, change of control or acquisition of all or a portion
                of our business by another company or third party, or in the
                event of a bankruptcy or related or similar proceedings; and
              </p>
            </li>
            <li>
              <p>
                Third parties as required by law or subpoena or if we reasonably
                believe that such action is necessary to (a) comply with the law
                and the reasonable requests of law enforcement; (b) to enforce
                our
                <a href="https://app.amplified.ai/termsofuse">
                  Terms of Use
                </a>{" "}
                or to protect the security or integrity of our Service; and/or
                (c) to exercise or protect the rights, property, or personal
                safety of Amplified, our visitors, or others.
              </p>
            </li>
          </ul>
          <p>
            We may also share information with others in an aggregated or
            otherwise anonymized form that does not reasonably identify you
            directly as an individual.
          </p>
          <h3 className="my-2">
            <strong>4. CONTROL OVER YOUR INFORMATION</strong>
          </h3>
          <p>
            <strong>Account and Data Sharing Settings.</strong> You may update
            your account information and may change some of your data sharing
            preferences by changing the settings at
            <a href="https://app.amplified.ai/settings">
              https://app.amplified.ai/settings
            </a>
            or by emailing us at
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>.
          </p>
          <p>
            <strong>How to control your communications preferences:</strong> You
            can stop receiving promotional email communications from us by
            clicking on the “unsubscribe a” provided in such communications. We
            make every effort to promptly process all unsubscribe requests. You
            may not opt out of service-related communications (e.g., account
            verification, transactional communications, changes/updates to
            features of the Service, technical and security notices).
          </p>
          <p>
            <strong>Modifying or deleting your information:</strong> If you have
            any questions about reviewing, modifying, or deleting your
            information, you can contact us directly at
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>. We
            may not be able to modify or delete your information in all
            circumstances.
          </p>
          <h3 className="my-2">
            <strong>5. HOW WE STORE AND PROTECT YOUR INFORMATION</strong>
          </h3>
          <p>
            <strong>Data storage and transfer</strong>: Your information
            collected through our website may be stored and processed in the
            United States or any other country in which Amplified or its
            affiliates or service providers maintain facilities. If you are
            located in the European Union or other regions with laws governing
            data collection and use that may differ from Unite States law,
            please note that we may transfer information, including personal
            information, to a country and jurisdiction that does not have the
            same data protection laws as your jurisdiction, and you consent to
            the transfer of information to the United States or any other
            country in which the Amplified or its parent, subsidiaries,
            affiliates, or service providers maintain facilities and the use and
            disclosure of information about you as described in this Privacy
            Policy.
          </p>
          <p>
            <strong>Keeping your information safe:</strong> We care about the
            security of your information and uses commercially reasonable
            physical, administrative, and technological safeguards to preserve
            the integrity and security of all information collected through our
            website. However, no security system is impenetrable and we cannot
            guarantee the security of our systems 100%. In the event that any
            information under our control is compromised as a result of a breach
            of security, we will take reasonable steps to investigate the
            situation and, where appropriate, notify those individuals whose
            information may have been compromised and take other steps, in
            accordance with any applicable laws and regulations.
          </p>
          <h3 className="my-2">
            <strong>6. CHILDREN’S PRIVACY</strong>
          </h3>
          <p>
            Amplified does not knowingly collect or solicit any information from
            anyone under the age of 13 on this Service. In the event that we
            learn that we have inadvertently collected personal information from
            a child under age 13, we will delete that information as quickly as
            possible. If you believe that we might have any information from a
            child under 13, please contact us at
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>.
          </p>
          <h3 className="my-2">
            <strong>7. AS TO OTHER WEB SITES AND SERVICES</strong>
          </h3>
          <p>
            The Services may contain as to and from third party websites of our
            business partners and social media sites and our users may post as
            to third party websites. If you follow a a to any of these websites,
            please note that these websites have their own privacy policies and
            that we do not accept any responsibility or liability for their
            policies. We strongly recommend that you read their privacy policies
            and terms and conditions of use to understand how they collect, use,
            and share information. We are not responsible for the privacy
            practices or the content on the websites of third party sites.
          </p>
          <h3 className="my-2">
            <strong>8. HOW TO CONTACT US</strong>
          </h3>
          <p>
            If you have any questions about this Privacy Policy or the website,
            please contact us at
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>.
          </p>
          <h3 className="my-2">
            <strong>9. CHANGES TO OUR PRIVACY POLICY</strong>
          </h3>
          <p>
            We may modify or update this Privacy Policy from time to time to
            reflect the changes in our business and practices, and so you should
            review this page periodically. When we change the policy in a
            material manner we will let you know and update the ‘last modified’
            date at the bottom of this page. If you object to any changes, you
            may close your account. Continuing to use our Services after we
            publish changes to this Privacy Policy means that you are consenting
            to the changes.
          </p>
          <p className="mt-4 mb-6">
            <em>This Privacy Policy was last modified on June 8, 2023.</em>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
